import React from "react";
import { graphql } from "gatsby";

import TEDxKIContainer from '../components/tedxki-container'

import * as styles from './team.module.css'

import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function TeamPage({ data }) {

  function sortByName(s1, s2) {
    if(s1['title'] && s1['title'].includes("Head of")) {
      return -1;
    } else if (s2['title'] && s2['title'].includes("Head of")) {
      return 1;
    }

    if(s1['title'] && s1['title'].includes("Organiser")) {
      return -1;
    } else if (s2['title'] && s2['title'].includes("Organiser")) {
      return 1;
    }

    if(s1['title'] && s1['title'].includes("Co-Lead")) {
      return -1;
    } else if (s2['title'] && s2['title'].includes("Co-Lead")) {
      return 1;
    }


    if ((s1.name > s2.name)) {
      return 1;
    } else if (s1.name < s2.name) {
      return -1;
    } else {
      return 0;
    }
  };

  const teams = data.allContentfulTeDxKi.edges[0].node.upcomingEvent.teams.sort(sortByName)


  return (
    <TEDxKIContainer>
        <div class="text-center">
          <h2 class="mb-4">Here are all the amazing people who make this year's event possible!</h2>
          <Container>
            {teams.map(t => {
              return (
              <div class="mt-4 mb-2" key={t.id}>
                <h3>{t.name}</h3>
                <Row className="justify-content-center">
                  {t.teamMembers.sort(sortByName).map(tm => {
                    return (
                      <Col lg={3} xs={10} className="mb-3">
                        {
                        <Card key={tm.id}>
                          
                          <Card.Img variant="top"
                            src={tm.photo.file.url}
                            alt={tm.name}
                          />
                          <Card.Body>
                            <Card.Title className="text-center">
                              <strong>{tm.name}</strong>
                              <p>{tm.title}</p>
                            </Card.Title>
                          </Card.Body>
                        </Card>
                    }
                      </Col>
                    )
                  })}
                </Row>
              </div>
              )
            })}
          </Container>
        </div>

        <div class="text-center">
        <h2>PAST TEAMS</h2>
        <Container fluid>
          <Row lg={2} xs={1}>
            {
              data.allContentfulTeDxKi.edges[0].node.pastEvents.map(event => {
                if (event.yearIdentifier >= 2023) /* The teams are only listed from 2023, because we don't have the older images */
                return (
                  <Col key={event.id} className="mt-3">
                    <Card>
                      <Card.Img variant="top" src={event.teamPhoto.file.url} className={styles.past_event_photo}/>
                      <Card.Body>
                        <Card.Title><strong>{event.yearIdentifier}</strong></Card.Title>
                        <a href={"/event/" + event.yearIdentifier} class="stretched-link"></a>
                      </Card.Body>
                    </Card>
                  </Col>
                )
              })
            }
          </Row>
        </Container>
      </div>
    </TEDxKIContainer>
  );


};

export const query = graphql`
  query {
    allContentfulTeDxKi {
      edges {
        node {
          upcomingEvent {
              yearIdentifier
              teams {
                id
                name
                teamMembers {
                  photo {
                    file {
                      url
                    }
                  }
                  title
                  name
                  id
                }
            }
                
          }
            pastEvents {
            name
            yearIdentifier
            image {
              file {
                url
              }
            }
            teamPhoto {
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`;
